$border-thickness: 1px;
$border-rule: $border-thickness solid var(--gray-6);
$padding-adjustment: calc(var(--table-cell-padding) - $border-thickness);

.rt-TablePlaceholder {
  border-radius: var(--space-1);
  border: 1px solid var(--gray-6);

  justify-content: center;
  align-content: center;
}

table.rt-TableRootTable {
  // this along with the specific borders on the th elements ensures the borders stay with the top-sticky th cells as you scroll
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;

  thead.rt-TableHeader {
    position: sticky;
    top: 0;
    // prevents table body checkboxes from obscuring while scrolling
    z-index: 1;

    tr.rt-TableRow {
      th.rt-TableColumnHeaderCell {
        color: var(--gray-11);
        background-color: var(--gray-4);
        font-weight: 500;
      }

      // borders around the header row (cells) without borders between cells
      th.rt-TableColumnHeaderCell {
        padding-top: $padding-adjustment;
        padding-bottom: $padding-adjustment;
        border-top: $border-rule;
        border-bottom: $border-rule;

        &:first-child {
          border-radius: var(--space-1) 0 0 0;

          padding-left: $padding-adjustment;
          border-left: $border-rule;
        }

        &:last-child {
          border-radius: 0 var(--space-1) 0 0;
          padding-right: $padding-adjustment;
          border-right: $border-rule;
        }
      }
    }
  }

  tbody.rt-TableBody {
    tr.rt-TableRow {
      &:hover,
      &[data-context-menu='true'] {
        // hovered
        background-color: var(--gray-a3);
      }

      &[data-checked='true'] {
        // checked
        background-color: var(--gray-a4);
      }

      &[data-checked='true']:hover {
        // checked & hovered
        background-color: var(--gray-a5);
      }

      &[data-active='true'] {
        // focused
        background-color: var(--accent-a3);

        &:hover {
          // focused & hovered
          background-color: var(--accent-a4);
        }

        &[data-checked='true'] {
          // focused & checked
          background-color: var(--accent-a5);
        }

        &[data-checked='true']:hover {
          // focused & checked & hovered
          background-color: var(--accent-a6);
        }
      }
    }
  }

  tfoot {
    background-color: var(--gray-4) !important;
  }

  // outside of tbody since it can apply to tfoot too
  td.rt-TableCell {
    padding-top: 0;
    padding-bottom: 0;
    vertical-align: middle;
    max-width: 200px;
    overflow: hidden;

    .rt-Badge {
      // pushes the badge up to align it better
      margin-bottom: 4px;
    }

    .StrikeZoneIcon {
      margin-top: 4px;
    }
  }
}
